export function isMobileDevice (uaString?: string): boolean {
  /**
   * `getHighEntropyValues` (https://caniuse.com/?search=highentropyvalues) can only be used at
   * this time by Chrome, Edge, Opera.
   * A Safari, Firefox fallback is called after using `navigator.userAgent`.
   */

  if ('userAgentData' in navigator) {
    const entropyValue = (navigator.userAgentData as any)
      ?.getHighEntropyValues(['model'])
      .then(({ mobile }: any) => mobile)

    if (typeof entropyValue === 'boolean') {
      return entropyValue
    }
  }

  uaString ||= navigator.userAgent

  return /(IE)?Mobile|iP(hone|od|ad)|Android|BlackBerry/.test(uaString)
}
